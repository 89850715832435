import React, { useEffect } from "react";

const VideoPlayer = ({ videoUrl, presentationId, thumbnail, quality }) => {
  let player;

  const loadVideo = () => {
    player = videojs(`video_player_${presentationId}`);
    player.ready(() => {
      player.src({
        src: `https://iwa-cdn.edalytics.com/${videoUrl}`,
        type: "application/x-mpegURL",
        withCredentials: true,
        caches: false,
      });
    });
  };

  useEffect(() => {
    loadVideo();
  }, [quality]); // reloads the video when quality is changed

  useEffect(() => {
    loadVideo();
    return () => {
      videojs(`video_player_${presentationId}`).dispose();
    };
  }, []);

  return (
    <React.Fragment>
      <video
        crossOrigin="use-credentials"
        id={`video_player_${presentationId}`}
        className="video-js vjs-theme-fantasy"
        controls
        preload="auto"
        poster={`https://iwa-cdn.edalytics.com/${thumbnail}`}
        data-setup="{}"
        width="100%"
        height="100%"
        style={{ height: "100%", width: "100%" }}
      />
    </React.Fragment>
  );
};

export default VideoPlayer;
