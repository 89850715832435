import React, { useState, useEffect, createContext } from "react";
import VerticalLayout from "./components/VerticalLayout/index";
import { authRoutes, userRoutes } from "./routes/allRoutes";
import Authmiddleware from "./routes/middleware/Authmiddleware";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NonAuthLayout from "components/NonAuthLayout";
import Login from "./pages/Authentication/Login";

import { setCookies } from "./pages/Authentication/CallBack";
import axios from "axios";

export const ContextData = createContext();

export const checkTokenExpireTime = () => {
  let tokenExpireTime = new Date(localStorage.getItem("lwa-tkn-exp"));
  console.log(new Date(tokenExpireTime));
  if (tokenExpireTime) {
    if (Date.now() > tokenExpireTime) {
      getActualTokenFromRefreshToken();
    }
  }
};
let reqBody = new URLSearchParams({
  grant_type: "refresh_token",
  refresh_token: localStorage.getItem("lwa-ref-tkn"),
  redirect_uri: `${location.protocol + "//" + location.host}/callback`,
  client_id: "1be3b7u433neg4lonscmgl11i",
  // client_secret: "",
});
let options = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const getActualTokenFromRefreshToken = () => {
  axios
    .post(
      `https://iwa.auth.eu-west-2.amazoncognito.com/oauth2/token`,
      reqBody,
      options
    )
    .then((res) => {
      console.log("TOKEN REFRESHED: ");
      if (res.data.access_token) {
        localStorage.setItem("lwa-acc-tkn", res.data.access_token);
        localStorage.removeItem("lwa-tkn-exp");
        localStorage.setItem(
          "lwa-tkn-exp",
          new Date(Date.now() + 60 * 60 * 1000 - 300000)
        );
      }
    })
    .then(() => {
      setCookies();
    })
    .catch((err) => console.error(err));
};

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [results, setResults] = useState([]);
  // let results = [];

  // useEffect(async () => {
  //   await axios
  //     .get(
  //       "https://a3vf3o9y41.execute-api.eu-west-2.amazonaws.com/dev/videos/",
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("lwa-acc-tkn"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.status == 401) {
  //         console.error("401 SESSION EXPIRED");
  //         getActualTokenFromRefreshToken();
  //       }
  //       // setResults(res.data);
  //       results = res.data;
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.status == 401) {
  //         console.error("401 SESSION EXPIRED");
  //         getActualTokenFromRefreshToken();
  //       }
  //     });
  // }, []);
  useEffect(() => {
    checkTokenExpireTime();
  }, []);
  useEffect(() => {
    const user = localStorage.getItem("authUser");
    if (user) {
      setIsLogged(true);
      setCurrentUser(user);
    } else {
      // No user is signed in.
    }
  }, []);
  return (
    <ContextData.Provider
      value={[
        isLogged,
        setIsLogged,
        currentUser,
        setCurrentUser,
        setResults,
        results,
      ]}
    >
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {userRoutes.map((route, idx) => (
            // <Route key={index} path={rout.path} component={rout.component} />
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={!isLogged}
              exact
            />
          ))}
        </Switch>
      </Router>
    </ContextData.Provider>
  );
}

export default App;
