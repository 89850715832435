import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";

import sessionList from "./data";
import { checkTokenExpireTime } from "App";

import WaterUtilityIcon from "../../assets/images/themeIcons/Water_utility_W.png";
import WasteWaterIcon from "../../assets/images/themeIcons/Wastewater_treatment_W.png";
import DrinkingWaterIcon from "../../assets/images/themeIcons/Drinking_water_W.png";
import CityIcon from "../../assets/images/themeIcons/City_W.png";
import CommunitiesIcon from "../../assets/images/themeIcons/Communities_W.png";
import WaterResourcesIcon from "../../assets/images/themeIcons/Water_resources_W.png";
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Nav,
  NavItem,
  CardBody,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Modal,
  CardHeader,
} from "reactstrap";

import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ContextData } from "App";

const removeBodyCss = () => {
  document.body.classList.add("no_padding");
};

function LearningHome() {
  const [activeTab, setActiveTab] = useState("5");
  const [results, setResults] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://a3vf3o9y41.execute-api.eu-west-2.amazonaws.com/dev/videos/",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("lwa-acc-tkn"),
          },
        }
      )
      .then((res) => {
        // console.log(res);
        if (res.status == 401) {
          console.error("401 SESSION EXPIRED");
          checkTokenExpireTime();
        }
        setResults(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.status == 401) {
          console.error("401 SESSION EXPIRED");
          checkTokenExpireTime();
        }
      });
  }, []);

  const toggle1 = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function updateBreadCrumbsItem(activeTabId) {
    switch (activeTabId) {
      case "5":
        return "Sessions";
      case "6":
        return "Themes";
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row className="d-flex align-items-center ">
            <Col xl="12" lg="12" md="12" sm="12">
              <Breadcrumbs
                title="Home"
                breadcrumbItem={updateBreadCrumbsItem(activeTab)}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12">
              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggle1("5");
                        }}
                      >
                        Sessions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          toggle1("6");
                        }}
                      >
                        Themes
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="5" className="p-3">
                      <SessionOnDate results={results} />
                    </TabPane>
                    <TabPane tabId="6" className="p-3">
                      <Themes results={results} />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

const SessionOnDate = ({ results }) => {
  const history = useHistory();

  const [showModalW1, setShowModalW1] = useState(false);
  const [showModalW2, setShowModalW2] = useState(false);
  const [selectedDay, setSelectedDay] = useState({
    week1: 1,
    week2: 5,
  });

  const toggleW1Modal = () => {
    setShowModalW1(!showModalW1);
    removeBodyCss();
  };
  const toggleW2Modal = () => {
    setShowModalW2(!showModalW2);
    removeBodyCss();
  };

  const getCermonyVideo = (day) => {
    let sessionWithZero = results.filter((val) => {
      return 0 === Number(val.session) && val.session !== "";
    });

    return sessionWithZero.filter((item) => {
      return item.day == day;
    });
  };

  const getSessionVideos = (sessionNo) => {
    return results.filter((s) => {
      return s.session == sessionNo;
    });
  };

  return (
    <React.Fragment>
      <CardHeader>
        <CardTitle>Week 1</CardTitle>
      </CardHeader>
      <Row>
        {sessionList.firstWeek.map((day) => {
          return (
            <Col md={2} md={4} xl={3} style={{ height: "100%" }}>
              <Card body>
                <CardTitle className="mb-3">{day.date}</CardTitle>
                <CardText className="font-weight-bold">Day {day.day}</CardText>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setSelectedDay({ ...selectedDay, week1: day.day });
                    toggleW1Modal();
                  }}
                >
                  Watch sessions
                </button>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Row />
      <CardHeader>
        <CardTitle>Week 2</CardTitle>
      </CardHeader>
      <Row>
        {sessionList.secondWeek.map((day) => {
          return (
            <Col md={2} md={4} xl={3} style={{ height: "100%" }}>
              <Card body>
                <CardTitle className="mb-3">{day.date}</CardTitle>
                <CardText className="font-weight-bold">Day {day.day}</CardText>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setSelectedDay({ ...selectedDay, week2: day.day });
                    toggleW2Modal();
                  }}
                >
                  Watch sessions
                </button>
              </Card>
            </Col>
          );
        })}
      </Row>

      {/* modal */}
      <Col sm={6} md={4} xl={8} className="mt-4">
        <Modal
          size={"lg"}
          isOpen={showModalW1}
          scrollable={true}
          toggle={toggleW1Modal}
          data-toggle="showModalW1"
          data-target="#showModalW1"
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="showModalW1">
              Sessions of the day
            </h5>
            <button
              type="button"
              onClick={() => {
                toggleW1Modal();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Col md={12}>
              <Card>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Table className="table  table-hover table center table-centered table-nowrap">
                        <tbody>
                          {sessionList.firstWeek
                            .find((days) => days.day === selectedDay.week1)
                            .sessions.map((session, index) => {
                              if (session.sessionNo === 0) {
                                return (
                                  <>
                                    <tr
                                      style={{
                                        backgroundColor: session.bgColor,
                                        // marginBottom: "25px",
                                      }}
                                    >
                                      <td
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={session.trackName}
                                      >
                                        {/* <img
                                          src={session.icon}
                                          height="50px"
                                          width="50px"
                                        /> */}
                                      </td>
                                      <th></th>
                                      <td className="text-wrap font-weight-bold">
                                        {session.title}
                                      </td>
                                      <td className="text-right">
                                        <button
                                          onClick={() => {
                                            history.push({
                                              pathname: `/ceremonies/${session.day}`,
                                              state: {
                                                cermonyVideo: getCermonyVideo(
                                                  session.day
                                                ),
                                              },
                                            });
                                          }}
                                          className="btn btn-primary waves-effect waves-light"
                                        >
                                          View details
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <tr
                                      style={{
                                        backgroundColor: session.bgColor,
                                        // marginBottom: "25px",
                                      }}
                                    >
                                      <td
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={session.trackName}
                                      >
                                        <img
                                          src={session.icon}
                                          height="50px"
                                          width="50px"
                                        />
                                      </td>
                                      <th>Session {session.sessionNo}</th>
                                      <td className="text-wrap">
                                        {session.title}
                                      </td>
                                      <td className="text-right">
                                        <button
                                          onClick={() => {
                                            history.push({
                                              pathname: `/session/${session.sessionNo}`,
                                              state: {
                                                sessionVideos: getSessionVideos(
                                                  session.sessionNo
                                                ),
                                              },
                                            });
                                          }}
                                          className="btn btn-primary waves-effect waves-light"
                                        >
                                          View details
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                toggleW1Modal();
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </Col>
      <Col sm={6} md={4} xl={8} className="mt-4">
        <Modal
          size={"lg"}
          isOpen={showModalW2}
          toggle={toggleW2Modal}
          scrollable={true}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="showModalW2">
              Sessions of the day
            </h5>
            <button
              type="button"
              onClick={() => {
                setShowModalW2(!showModalW2);
                removeBodyCss();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Col md={12}>
              <Card>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Table className="table  table-hover mb-1  table center table-centered table-nowrap">
                        <tbody>
                          {sessionList.secondWeek
                            .find((days) => days.day === selectedDay.week2)
                            .sessions.map((session, index) => {
                              if (session.sessionNo === 0) {
                                return (
                                  <>
                                    <tr
                                      style={{
                                        backgroundColor: session.bgColor,
                                        // marginBottom: "25px",
                                      }}
                                    >
                                      <td
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={session.trackName}
                                      >
                                        {/* <img
                                          src={session.icon}
                                          height="50px"
                                          width="50px"
                                        /> */}
                                      </td>
                                      <th></th>
                                      <td className="text-wrap font-weight-bold">
                                        {session.title}
                                      </td>
                                      <td className="text-right">
                                        <button
                                          onClick={() => {
                                            history.push({
                                              pathname: `/ceremonies/${session.day}`,
                                              state: {
                                                cermonyVideo: getCermonyVideo(
                                                  session.day
                                                ),
                                              },
                                            });
                                          }}
                                          className="btn btn-primary waves-effect waves-light"
                                        >
                                          View details
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <tr
                                      style={{
                                        backgroundColor: session.bgColor,
                                        // marginBottom: "25px",
                                      }}
                                    >
                                      <td
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={session.trackName}
                                      >
                                        <img
                                          src={session.icon}
                                          height="50px"
                                          width="50px"
                                        />
                                      </td>
                                      <th>Session {session.sessionNo}</th>
                                      <td className="text-wrap">
                                        {session.title}
                                      </td>
                                      <td className="text-right">
                                        <button
                                          onClick={() => {
                                            history.push({
                                              pathname: `/session/${session.sessionNo}`,
                                              state: {
                                                sessionVideos: getSessionVideos(
                                                  session.sessionNo
                                                ),
                                              },
                                            });
                                          }}
                                          className="btn btn-primary waves-effect waves-light"
                                        >
                                          View details
                                        </button>
                                        {/* <button
                                          onClick={() => {
                                            history.push(
                                              `/session/${session.sessionNo}`
                                            );
                                          }}
                                          className="btn btn-primary waves-effect waves-light"
                                        >
                                          View details
                                        </button> */}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setShowModalW2(!showModalW2);
                removeBodyCss();
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </Col>
    </React.Fragment>
  );
};
const Themes = ({ results }) => {
  const history = useHistory();

  const [showTrackSessionModal, setShowTrackSessionModal] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(1);
  const themeCards = [
    {
      title: "Track 1",
      subtitle: "Water utility management",
      icon: WaterUtilityIcon,
      bgColor: "#c7eafb",
    },
    {
      title: "Track 2",
      subtitle: "Wastewater treatment & resource recovery",
      icon: WasteWaterIcon,
      bgColor: "#d4ebdd",
    },
    {
      title: "Track 3",
      subtitle: "Drinking water & potable reuse",
      icon: DrinkingWaterIcon,
      bgColor: "#fad5e6",
    },
    {
      title: "Track 4",
      subtitle: "City-scale planning & operations",
      icon: CityIcon,
      bgColor: "#e2d8eb",
    },
    {
      title: "Track 5",
      subtitle: "Communities, communication & partnership",
      icon: CommunitiesIcon,
      bgColor: "#f7dcc6",
    },
    {
      title: "Track 6",
      subtitle: "Water resources & large-scale water management",
      icon: WaterResourcesIcon,
      bgColor: "#ffedcd",
    },
  ];

  const toggleTrackSessionModal = () => {
    setShowTrackSessionModal(!showTrackSessionModal);
    removeBodyCss();
  };

  const getSessionVideos = (sessionNo) => {
    return results.filter((s) => {
      return s.session == sessionNo;
    });
  };

  let filtered = [];
  sessionList.firstWeek.map((day) => {
    return day.sessions.map((x) => {
      if (x.trackNo == selectedTrack) {
        filtered.push(x);
      }
    });
  });
  sessionList.secondWeek.map((day) => {
    return day.sessions.map((x) => {
      if (x.trackNo == selectedTrack) {
        filtered.push(x);
      }
    });
  });
  return (
    <React.Fragment>
      <Row>
        {themeCards.map((val, index) => {
          return (
            <Col md={2}>
              <Card
                body
                style={{
                  background: val.bgColor,
                  height: "100%",
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedTrack(index + 1);
                  setShowTrackSessionModal(true);
                }}
              >
                <img
                  src={val.icon}
                  className="text-left mb-2"
                  height="70px"
                  width="70px"
                />
                <CardTitle
                  className="mt-0"
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  {val.title}
                </CardTitle>
                <CardText
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  {val.subtitle}
                </CardText>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Col sm={6} md={4} xl={8} className="mt-4">
        <Modal
          size={"lg"}
          scrollable={true}
          toggle={toggleTrackSessionModal}
          isOpen={showTrackSessionModal}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Sessions belonging to Track {selectedTrack}-
              {themeCards[selectedTrack - 1].subtitle}
            </h5>
            <button
              type="button"
              onClick={() => {
                setShowTrackSessionModal(!showTrackSessionModal);
                removeBodyCss();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Col md={12}>
              <Card>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Table className="table  table-hover mb-1  table center table-centered table-nowrap">
                        <tbody>
                          {filtered.map((session) => {
                            return (
                              <>
                                <tr
                                  style={{
                                    backgroundColor: session.bgColor,
                                    // marginBottom: "25px",
                                  }}
                                >
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={session.trackName}
                                  >
                                    <img
                                      src={session.icon}
                                      height="50px"
                                      width="50px"
                                    />
                                  </td>
                                  <th>Session {session.sessionNo}</th>
                                  <td className="text-wrap">{session.title}</td>
                                  <td className="text-right">
                                    <button
                                      onClick={() => {
                                        history.push({
                                          pathname: `/session/${session.sessionNo}`,
                                          state: {
                                            sessionVideos: getSessionVideos(
                                              session.sessionNo
                                            ),
                                          },
                                        });
                                      }}
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      View details
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                setShowTrackSessionModal(!showTrackSessionModal);
                removeBodyCss();
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      </Col>
    </React.Fragment>
  );
};
export default LearningHome;
